import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useClassSessions from "../../hooks/useClassSessions";
import useTitle from "../../hooks/useTitle";
import ClassSelector from "../../components/Calendar/ClassSelector";
import DaySelector from "../../components/Calendar/DaySelector";
import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";
import { PodsCalendarWrapper } from "./style";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import { useDaySelection } from "../../hooks/useDaySelection";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../Utils/constants";
import { Typography } from "@mui/material";

interface IProps {
  title: string;
}

const PodsClasses: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");

  const { selectedService, company, selectedDay, setSelectedDay } =
    useContext(CheckoutContext) as CheckoutContextProps;

  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || "",
    selectedService,
    dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD")
  );


  return (
    <PodsCalendarWrapper>
      <ClassSelector type="class" subType="pods" serviceId={serviceId || ""} />
      <DaySelector
        selectedPickerDate={selectedPickerDate}
        setSelectedPickerDate={setSelectedPickerDate}
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        onlySevenDaysInAdvance={company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID}
      />

      <ClassSessionSelector
        sessions={sessions}
        isLoading={isLoadingSessions}
        type="pods"
      />

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && (
        <Typography 
          variant="body2" 
          sx={{ 
            marginTop: 2, 
            fontStyle: 'italic',
            color: 'text.secondary'
          }}
        >
          Class timetable is updated daily and subject to change.
        </Typography>
      )}


      {company?.hasFooter && <CustomFooter />}
    </PodsCalendarWrapper>
  );
};

export default PodsClasses;
