import { useContext, useEffect, useState } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../context/CheckoutContext";

import axiosInstance from "../services/axiosInstance";
import { auth } from "../config/firebase";
import { useAuth } from "../context/AuthContext";
import useUser from "./useUser";

export interface Company {
  id: string;
  name: string;
  logo: string;
  cancellation_policy: number;
  currency: string;
  companyAddress: string;
  companyEmail: string;
  companyPhoneNumber: string;
  mapLink: string;
  paymentsActive: boolean;
  timeUnit: "session" | "hour";
  resourcesInformation?: ResourceInformation[];
  branches: Branch[];
  footerPagesLinks: FooterPagesLinks;
  timezone: string;
  serviceDurationMultiples: number;
  vouchers: Discount[];
  hasAuthentication?: boolean;
  showSidebar?: boolean;
  hasFooter?: boolean;
  primaryColor?: string;
  website?: string;
  countdownTimeInMinutes?: number;
  confirmationDisclaimer?: string;
  packageDisclaimer?: string;
  showSKMDLogo?: boolean;
  showContactDetails?: boolean;
  membershipDisclaimer?: string;
  membershipHeader?: string;
  logoDimensions: {
    width: string;
    height: string;
  };
  bookingRange: {
    start: string;
    end: string;
  };
  tenantId: string;
  gtmId: string;
  faviconUrl: string;
  whatsappHelpPhoneNumber: string;
  sidebarContent: {name: string , path:string}[];
  supportSocialAuthentication?: boolean;
  marketplaces: {
    name: string;
    gtmId: string;
  }[]
  createAccountFields?: {
    firstName: boolean;
    lastName: boolean;
    phoneNumber: boolean;
    gender: boolean;
    dateOfBirth: boolean;
    whereDidYouHearAboutUs: boolean;
    favoriteSports: boolean;
    nationality: boolean;
    employeeId: boolean;
    emergencyContactName: boolean;
    emergencyContactMobile: boolean;
    groupCode: boolean;
    marketingConsent: boolean;
    childrenInformation: boolean;
    podsInformation: boolean;
  }
  hideDiscountRedemptionSection?: boolean;
  isBetaVersion?: boolean;  
}

export interface Voucher {
  credit: number;
  currency: string;
  price: number;
  type: string;
  name?: string;
  id: string;
  duration: number;
}

export interface Membership {
  serviceId: string;
  currency: string;
  price: number;
  type: string;
  days: number;
  name?: string;
  id: string;
  maxUsage: number;
}

export type Discount = Voucher | Membership;

export interface FooterPagesLinks {
  aboutUs: string;
  terms: string;
  privacy: string;
  refund: string;
  marketingConsent?: string;
}

export interface ResourceInformation {
  resourceId: string;
  timeUnit: "session" | "hour";
  disclaimer: string;
}

export interface Branch {
  id: string;
  name: string;
  address: string;
  companyId: string;
  startTime: string;
  endTime: string;
  bufferTime: number;
  services: Service[];
  isActive: boolean;
}

export interface Service {
  id: string;
  name: string;
  branchId: string;
  companyId: string;
  description: string;
  maxQuantity?: number;
  cutoff?: number;
  resource: string[];
  resourceNames: string[];
  advanceBookingDays?: number;
  type?: "class" | "coach" | "standard";
  subType?: "gym" | "kids" | "fun" | "fitness" | "pods";
  isActive?: boolean;
  heading?: string;
  addons?: string[];
  image?: string;
  serviceDurationMultiples?: number;
  showResourcesSelector?: boolean;
  resources: Resource[];
  workingHours: {
    start: string;
    end: string;
  }
  isBookingDisabled?: boolean;
}
export interface Resource {
  id: string;
  name: string;
  services: Service[];
  active: boolean;
}

const useCompany = (companyId: string) => {
  const {
    setResourcesMap,
    setServicesMap,
    setBranchesMap,
    setCompany,
    company,
  } = useContext(CheckoutContext) as CheckoutContextProps;
  const [loading, setLoading] = useState<boolean>(true);
  const { getUser } = useUser();

  const {auth , setUser} = useAuth();

  useEffect(() => {
    const fetchAllCompanies = async () => {
      if (company && company?.id === companyId) return;
      setLoading(true);
      try {
        const { data: response } = await axiosInstance.post(
          `/booking/getCompany`, {
          companyId
        }
        );
        const selectedCompany: Company = response.data

        if(selectedCompany.tenantId) auth.tenantId = selectedCompany.tenantId

        const updatedBranchesMap = new Map<string, Branch>();
        
        const updatedServicesMap = new Map<string, Service>();
        const updatedResourcesMap = new Map<string, Resource>();
        selectedCompany.branches.forEach((branch) => {
          console.log("[BRANCH]",branch.isActive);
          if(branch.isActive != undefined && !branch.isActive){
            return ;
          }
          if (branch.services.length > 0) {
            updatedBranchesMap.set(branch.id, branch);
          }
          branch.services.forEach((service) => {
            if(service.isActive != undefined && !service.isActive){
              return ;
            }
            updatedServicesMap.set(service.id, service);
            service.resources?.forEach((resource) => {
              if(resource.active != undefined && !resource.active){
                return;
              }
              const resourceEntry = updatedResourcesMap.get(resource.id);
              const serviceName = !!resource?.name
                ? resource?.name
                : service.name;

              if (resourceEntry) {
                resourceEntry.services.push(service);
              } else {
                updatedResourcesMap.set(resource.id, {
                  id: resource?.id,
                  name: serviceName,
                  services: [service],
                  active: resource.active
                });
              }
            });
          });
        });
        setResourcesMap(updatedResourcesMap);
        setServicesMap(updatedServicesMap);
        setBranchesMap(updatedBranchesMap);
        setCompany(selectedCompany);
        if(auth.currentUser){
          const currentUser = await getUser(auth.currentUser.uid);
          setUser(currentUser); 
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    if (companyId) fetchAllCompanies();
    else setLoading(false)
  }, [companyId, setBranchesMap, setCompany, setResourcesMap, setServicesMap]);




  return { loading, company };
};

export default useCompany;
