import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

import service1Img from '../../assets/images/DFC-class.png';
import service2Img from '../../assets/images/DFC-padel.png';
import service3Img from '../../assets/images/DFC-kids.jpg';

const ActivityOptions: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const companyId = company?.id || searchParams.get('companyId') || '';

  const activities = [
    { title: 'What’s on Classes', image: service1Img, path: '/Class' , URLParams: {branchId: "7147e79c997d42d89dcacee092e9a1ea"} },
    { title: 'book a court', image: service2Img, path: '/Calendar' },
    { title: 'book a People of Determination class', image: service3Img, path: '/PodsClasses' },
  ];

  const handleActivityClick = (activity: any) => {
    let fullPath = `${activity.path}?companyId=${companyId}`;
    if (activity.URLParams) {
      const params = new URLSearchParams(activity.URLParams as Record<string, string>);
      fullPath += `&${params.toString()}`;
    }
    navigate(fullPath);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: 3,
      }}
    >
      {activities.map((activity, index) => (
        <Box
          key={index}
          onClick={() => handleActivityClick(activity)}
          sx={{
            width: 400,
            height: 200,
            backgroundImage: `url(${activity.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '16px',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
            borderRadius: '12px'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              transition: 'background-color 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              overflow: 'hidden',
              borderRadius: '12px'
            }}
          />
          <Typography 
            variant="h6" 
            color="white" 
            sx={{ 
              textShadow: '2px 2px 4px rgba(0,0,0,0.8)', 
              fontWeight: 'bold',
              position: 'relative',
              zIndex: 1,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
              },
              textAlign: 'center',
              width: '100%',
              padding: '0 16px',
            }}
          >
            {activity.title.toUpperCase()}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ActivityOptions;
