import React from "react";
import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { IChildInputProps } from "./Types";
import { FieldErrors } from "react-hook-form";

const ChildInput: React.FC<IChildInputProps> = ({
  errors,
  register,
  field,
  index,
  fieldName,
}) => {

  const getErrorMessage = (errors: FieldErrors, path: string) => {
    return path.split('.').reduce((acc: any, part) => acc && acc[part], errors)?.message;
  };

  return (
    <Stack key={field.id} sx={{ width: "100%" }}>

      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "15px",
          width: "100%",
        }}
      >
        <Input
          id={`${fieldName}-${index}-name`}
          type="text"
          placeholder={"Enter name"}
          sx={{
            border: "1px solid #D1D5DB",
            height: "42px",
            borderRadius: "6px",
            px: "20px",
            flex: 1,
            "&.Mui-error": {
              border: "1px solid #C82E34",
            },
            "&::before": {
              content: "none",
            },
          }}
          {...register(`${fieldName}.${index}.name` as const, {
            required: `${fieldName === "children" ? "Child" : "People of determination"} name is required`,
          })}
          error={!!getErrorMessage(errors, `${fieldName}.${index}.name`)}
        />

        <Select
          placeholder="Age"
          defaultValue={0}
          sx={{
            borderColor: "#D1D5DB",
            height: "42px",
            borderRadius: "6px",
            px: "18px",
            width: "111px",
          }}
          {...register(`${fieldName}.${index}.age` as const, {
            required: `${fieldName === "children" ? "Child" : "People of determination"} age is required`,
            min: { value: 4, message: `${fieldName === "children" ? "Child" : "People of determination"} age is required` },
          })}
          error={!!getErrorMessage(errors, `${fieldName}.${index}.age`)}
        >
          <MenuItem value={0} disabled>
            <Typography component={"span"} sx={{ color: "#808080" }}>
              Age
            </Typography>
          </MenuItem>
          {new Array(12).fill(0).map((_, i) => (
            <MenuItem value={i + 4} key={`${index}-${i}`}>
              {i + 4}
            </MenuItem>
          ))}
        </Select>
      </Stack>

    
    </Stack>
  );
};

export default ChildInput;
